/* .gallery-container{
    position: relative;
    padding-bottom: 50px;
    font-family: 'Arial', sans-serif;
    min-height: calc(100vh - 60px - 180px);  
    color: #333;
    overflow: hidden;

    background-color: #f4f4f4; 
    
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
} */
.gallery-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    padding: 20px;
    margin-bottom: 60px;
  }
  
  .photo-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }
  
  .photo-item img {
    width: 100%;
    height: auto;
    max-height: 200px; /* Ensure a maximum height */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    object-fit: cover;
  }
  
  .photo-description {
    margin-top: 10px;
    font-size: 14px;
    color: #333;
    text-align: center;
  }
  