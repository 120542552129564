.player-card-container .player-card {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 15px;
    max-width: 400px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 100%; 
    height: 100%;
    box-sizing: border-box; 
    justify-content: center; 
    align-items: center;     
    padding: 20px;  
  }
  
  .player-card-container .player-card h3 {
    margin-bottom: 10px;
  }

  .player-card-container .player-register-player-address {
    height: 64px; /* Three lines for the address */
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: normal; /* Allow wrapping for multiple lines */
    text-overflow: ellipsis;
  }
  
  .player-card-container .player-card img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-top: 10px;
  }
  
  .player-card-container .player-card p {
    margin: 5px 0;
    font-size: 14px;
  }
  
  .player-card-container .player-card strong {
    font-weight: bold;
  }
  
  .player-card-container .player-card .captain {
    color: #f00;
    font-weight: bold;
  }
  