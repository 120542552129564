/* Base styles */
.address-page {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  flex-wrap: wrap; /* Allow items to wrap to the next line on small screens */
}

.address-page .add-address,
.address-page .address-list {
  width: 45%;
  margin-bottom: 20px; /* Add margin to separate sections */
}

.address-page form {
  display: flex;
  flex-direction: column;
}

.address-page form div {
  margin-bottom: 10px;
}

.address-page form button {
  width: 100px;
  align-self: flex-end;
}

.address-page table {
  width: 100%;
  border-collapse: collapse;
}

.address-page table th,
.address-page table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.address-page table th {
  background-color: #f2f2f2;
  text-align: left;
}

.address-page .delete-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.address-page .delete-button:hover {
  background-color: #cc0000;
}

.address-page .confirmation-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.address-page .popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.address-page .popup-content button {
  margin: 10px;
}

/* Media Queries for Responsive Design */

/* Mobile screens from 300px to 600px */
@media (min-width: 300px) and (max-width: 600px) {
  .address-page {
    flex-direction: column; /* Stack items vertically on smaller screens */
    padding: 15px; /* Adjust padding for smaller screens */
  }

  .address-page .add-address,
  .address-page .address-list {
    width: 100%; /* Full width on small screens */
    margin-bottom: 20px; /* Add margin to separate sections */
  }

  .address-page form button {
    width: 100%; /* Full width button for better usability */
    align-self: center; /* Center the button */
  }

  .address-page table th,
  .address-page table td {
    font-size: 14px; /* Smaller font size for table cells */
    padding: 6px; /* Adjust padding */
  }

  .address-page .popup-content {
    width: 90%; /* Increase width of popup content */
    max-width: 400px; /* Set maximum width for the popup */
  }
}

/* Tablets and small screens */
@media (min-width: 600px) {
  .address-page {
    flex-direction: row; /* Row layout on larger screens */
    justify-content: space-between; /* Space between the sections */
  }

  .address-page .add-address,
  .address-page .address-list {
    width: 45%; /* Adjust width for side-by-side layout */
    margin-bottom: 0; /* Remove bottom margin for side-by-side layout */
  }
}

/* Laptops and larger screens */
@media (min-width: 992px) {
  .address-page {
    padding: 40px; /* Increase padding on larger screens */
  }

  .address-page form button {
    width: 120px; /* Larger button width */
  }
}

/* Large displays */
@media (min-width: 1200px) {
  .address-page {
    padding: 60px; /* Increase padding on very large screens */
  }
}
