.controller-container {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Space between the heading and the body */
    padding: 20px;
    border: 1px solid #ccc; /* Optional: Adds a border around the container */
    border-radius: 8px; /* Optional: Adds rounded corners */
    background-color: #f9f9f9; /* Light background color for the container */
    width: 80vw;
    /* height: 105vh; */
}

.controller-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    width: 98%;
    gap: 20px;
    background-color: #e0e0e0; /* Light gray background for the heading */
    border-radius: 8px; /* Optional: Adds rounded corners */
}

.controller-matchNo {
    /* width: 100px;  */
    height: 100%; 
    background-color: #99cc99;  /* Light red background for match number */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px; /* Optional: Adds rounded corners */
    color: #333; /* Text color */
    width: 30%;
    flex-direction: column;
    
}

.controller-match-heading {
    flex-grow: 1; /* Takes up remaining space */
    background-color: #99cc99; /* Light green background for match heading */
    padding: 0 20px;
    text-align: center;
    border-radius: 4px; /* Optional: Adds rounded corners */
    color: #333; /* Text color */
    
}

.controller-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    gap: 20px; /* Space between the divs in the body */
    height: 100%;
}

.left-div,
.center-div,
.right-div {
    width: 33.33%; /* Adjust the width as needed */
    min-height: 75vh; /* Ensures div can grow with content */
    height: auto;
    border-radius: 4px; /* Optional: Adds rounded corners */
    color: #fff; /* Text color */
    overflow: auto; 
    flex-direction: column;
     display: flex;
    padding-bottom: 5%;
    align-items: center;
    justify-content: center;
    word-wrap: break-word;
}

.left-div {
    background-color: #ffcc99; 

}

.center-div {
    background-color: #66b3ff; 

}

.right-div {
    background-color: #ffcc99; 
 
}

.controller-h3{
    font-size:x-large;
}


.team-name{
    display: flex;
    align-items: center;
}

/* .controller-buttons{
    display: flex;
    gap: 10px;
}
.controller-buttons button {
    padding: 8px 12px; 
  } */

  .controller-buttons {
    display: flex;
    gap: 10px;
}

.controller-buttons button,
.controller-reset-all {
    padding: 8px 12px; /* Add some padding to the buttons for better spacing */
    background-color: #4CAF50; /* Green background */
    border: none; /* Remove default border */
    border-radius: 5px; /* Rounded corners */
    color: white; /* White text color */
    font-size: 16px; /* Increase font size */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth transition */
}
.controller-reset-all{
    background-color: red;
    color: white;
}

.controller-buttons button:hover {
    background-color: #45a049; /* Darker green on hover */
}

.controller-buttons button:active {
    background-color: #3e8e41; /* Even darker green on click */
}


.controller-number-input {
    height: 30px;
    width: 70px;
    padding: 5px; /* Add some padding */
    border: 1px solid #ccc; /* Light gray border */
    border-radius: 4px; /* Rounded corners */
    font-size: 16px; /* Increase font size */
    transition: border-color 0.3s ease; /* Smooth transition */
}

.controller-number-input:focus {
    border-color: #65a768;   /* Green border on focus */
    outline: none; /* Remove default outline */
}

input[type="text"],
input[type="number"] {
    padding: 8px 12px; /* Add some padding */
    border: 1px solid #ccc; /* Light gray border */
    border-radius: 4px; /* Rounded corners */
    font-size: 16px; /* Increase font size */
    transition: border-color 0.3s ease; /* Smooth transition */
}

input[type="text"]:focus,
input[type="number"]:focus {
    border-color: #4CAF50; /* Green border on focus */
    outline: none; /* Remove default outline */
}


  .controller-player-content{
    display: flex;
  align-items: center; /* Optional: Vertically align items if needed */
  gap: 10px; 
  }

  .controller-number-input{
    height: 30px;
    width: 70px;
  }



/* Medium screens (10-14 inch laptops) */
@media (min-width: 700px) and (max-width: 1024px) {
    .left-div,
    .center-div,
    .right-div {
        width: 32%; /* Slightly decrease the width */
        height: 80vh; /* Increase the height */
    }
}

/* Small screens (tablets) */
@media (max-width: 700px) {
    .left-div,
    .center-div,
    .right-div {
        width: 100%; /* Full width for each div */
        min-height: 75vh;
        height: auto    ;
        font-size: 14px; /* Smaller font size */
        padding: 5px;
    }
    .controller-body {
        display: flex;
        flex-direction: column; 
    }
    .controller-matchNo{
        font-size: small;
    }
}

/* Mobile screens */
@media (max-width: 480px) {
    .left-div,
    .center-div,
    .right-div {
        width: 100%; /* Full width for each div */
        height: auto; /* Auto height for mobile */
        margin-bottom: 20px; /* Add some space between divs */
    }
    .controller-body {
        display: flex;
        flex-direction: column; 
    }
    .controller-matchNo{
        font-size: 0.81rem;
    }
}