.footer {
  bottom: 0;
  width: 100%;
  background-color: #333;
  color: white;
  text-align: center;
  font-family: 'Arial', sans-serif;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  background-image: url(../../../public/Footerbg.png);
  padding: 20px 0;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  justify-content: space-around;
}

.footer-useful-links,
.footer-contact-details,
.footer-quick-links,
.footer-logo-social {
  flex: 1 1 300px;
  margin: 10px;
}

.footer-logo-social img {
  max-width: 100%;
  height: auto;
}

.footer-text {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  width: 100%; /* Ensure it takes the full width */
}

.footer-welcome {
  padding-top: 3%;
  padding-bottom: 3%;
  text-align: justify;
}

.footer-welcome ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-welcome li {
  font-size: 16px;
  line-height: 1.5;
}

.footer-useful-links .social-icons {
  display: flex;
  justify-content: center;
  gap: 40px;
}

.footer-useful-links .social-icons svg {
  color: white;
  font-size: 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.footer-useful-links .social-icons svg:hover {
  color: #1da1f2;
}

.footer-quick-links ul,
.footer-useful-links ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-quick-links ul li,
.footer-useful-links ul li {
  margin: 10px 0;
  font-weight: bold;
}

.footer-quick-links ul li a,
.footer-useful-links ul li a {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-quick-links ul li a:hover,
.footer-useful-links ul li a:hover {
  color: #1da1f2;
}

.footer-contact-details h2 {
  margin-bottom: 15px;
}

.footer-contact-details h4 {
  font-size: 16px;
  margin: 0;
}

.contact-content{
  margin-right: 25px;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-welcome li{
    padding: 25px;
    text-align: center;
    
  }

  .footer-useful-links,
  .footer-contact-details,
  .footer-quick-links,
  .footer-logo-social {
    flex: 1 1 100%;
    text-align: center;
    margin: 10px 0; /* Adjust margin for better spacing on mobile */
  }

  .footer-useful-links .social-icons {
    gap: 20px;
  }

  .footer-contact-details h4 {
    font-size: 14px;
  }
  .contact-content{
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 10px;
  }
}
