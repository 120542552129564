.event-card-container .event-card {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 15px;
    max-width: 300px;
    background-color: #f0f8ff;
    max-width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box; 
  }
  
  .event-card-container .event-card h3 {
    margin-bottom: 10px;
    color: #333;
  }
  
  .event-card-container .event-card p {
    margin: 5px 0;
    font-size: 14px;
  }
  
 .event-card-container .event-card strong {
    font-weight: bold;
  }