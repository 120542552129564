.member-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 16px;
  margin: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%; /* Full width for responsive design */
  max-width: 900px; /* Maximum width */
  overflow: hidden;
  background-color: white;
  transition: transform 0.2s;
}

/* Special styling for Founding Members */
.member-card.founding-member {
  max-width: 600px; /* Smaller width for Founding Members */
}

.member-card:hover {
  transform: translateY(-5px);
}

.member-photo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px; /* Adjust margin for better spacing */
}

.member-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center; /* Center text for better readability */
  width: 100%; /* Full width for responsive design */
}

.member-details h2 {
  margin: 0;
  font-size: 1.5em;
}

/* Centered name for Founding Members */
.centered-name {
  text-align: center;
}

.member-details p {
  margin: 4px 0;
}

.member-achievements {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 500px){
  .member-card{
    margin-top: 20px;
    margin-right: 0px;
    margin-left: 0px;
  }
}

/* Media Queries for Responsive Design */
@media (min-width: 600px) {
  .member-card {
    flex-direction: row;
    align-items: flex-start;
  }

  .member-photo {
    margin-bottom: 0;
    margin-right: 20px;
  }

  .member-details {
    text-align: left;
    align-items: flex-start;
  }
}
