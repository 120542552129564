.home {
    border-top: 10px solid #FF4500;
    background-color: #e7e7e7;
    justify-content: center;
  }
  
  .about-mba-container {
    position: relative;
    padding-bottom: 50px;
    font-family: 'Arial', sans-serif;
    min-height: calc(100vh - 60px - 180px);
    color: #333; /* Dark gray text for readability */
    overflow: hidden;
    background-color: #f4f4f4;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  }
  
  .about-mba-header {
    text-align: center;
    color: #FF4500; /* Bright orange color to symbolize basketball */
    margin-bottom: 20px;
    font-size: 2.5em; /* Larger font size */
    font-weight: bold; /* Bold header */
    text-transform: uppercase; /* Uppercase text */
  }
  
  .about-mba-nav {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    background-color: #333; /* Solid dark background for nav */
    padding: 10px;
    border-radius: 5px;
    z-index: 1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Small shadow for depth */
  }
  
  .about-mba-nav li {
    margin: 0 15px;
  }
  
  .about-mba-nav a {
    text-decoration: none;
    color: #fff; /* White text for nav links */
    font-weight: bold;
    transition: color 0.3s ease;
    padding: 5px 10px; /* Padding for better click area */
  }
  
  .about-mba-nav a:hover {
    color: #FF4500; /* Bright orange hover color */
    text-shadow: 0 0 5px rgba(255, 69, 0, 0.5); /* Subtle glow effect */
  }
  
  .sub-tab-content {
    padding: 20px; /* Padding for content area */
    background-color: #e7e7e7;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    text-align: left; /* Left-aligned text */
    font-size: 1em; /* Regular font size */
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .about-mba-nav {
      flex-direction: column;
      align-items: center;
    }
  
    .about-mba-nav li {
      margin: 10px 0;
    }
  
    .about-mba-header {
      font-size: 2em;
    }
  
    .sub-tab-content {
      padding: 15px;
      font-size: 0.9em;
    }
  }
  
  @media (max-width: 480px) {
    .about-mba-header {
      font-size: 1.5em;
    }
  
    .sub-tab-content {
      padding: 10px;
      font-size: 0.8em;
    }
  }
  