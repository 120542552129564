.team-detail-container {
    padding: 20px;
  }
  
  .team-detail-container {
    padding: 20px;
  }

  .team-detail-headling{
    background-color: black;
    color: white;
  }
  
  .team-detail-container h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .team-detail-container h2 {
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Flexible grid layout */
    gap: 20px; /* Space between cards */
    justify-items: center; /* Center align the cards */
    margin-top: 20px;
  }

  @media screen and (min-width: 1080px) {
    .grid-container {
      grid-gap: 50px; /* Increase gap for larger screens */
    }
  }
  
  @media screen and (min-width: 1400px) {
    .grid-container {
      grid-gap: 60px; /* Even larger gap for very large screens */
    }
  }