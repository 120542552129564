/* 

.member-form-inputbox,
.member-form-photo {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 14px;
  background-color: #fff;
  color: #333;
}

.member-form-inputbox:focus,
.member-form-photo:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.member-form-button {
  padding: 10px 15px;
  margin-top: 10px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.member-form-button:hover {
  background-color: #0056b3;
}

.required {
  color: red;
  margin-left: 5px;
  font-size: 14px;
}

.add-member-details .photo-preview {
  display: block;
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
}

.add-member-details .member-list table {
  width: 100%;
  border-collapse: collapse;
}

.add-member-details .member-list th,
.add-member-details .member-list td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.add-member-details .delete-button {
  background-color: red;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.confirmation-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}
 */
/* Base styling for all devices */
.member-form-inputbox,
.member-form-photo {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 14px;
  background-color: #fff;
  color: #333;
}

.member-form-inputbox:focus,
.member-form-photo:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.member-form-button {
  padding: 10px 15px;
  margin-top: 10px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.member-form-button:hover {
  background-color: #0056b3;
}

.required {
  color: red;
  margin-left: 5px;
  font-size: 14px;
}

.add-member-details .photo-preview {
  display: block;
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
}

.add-member-details .member-list table {
  width: 100%;
  border-collapse: collapse;
}

.add-member-details .member-list th,
.add-member-details .member-list td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.add-member-details .delete-button {
  background-color: red;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.confirmation-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

/* Responsive adjustments */

/* For mobile devices (5-7 inches) */
@media (max-width: 767px) {
  .member-form-inputbox,
  .member-form-photo {
    font-size: 12px;
    padding: 8px;
  }

  .member-form-button {
    font-size: 14px;
    padding: 8px 12px;
  }

  .add-member-details .photo-preview {
    width: 60px;
    height: 60px;
  }

  .add-member-details .member-list th,
  .add-member-details .member-list td {
    padding: 8px;
  }
}

/* For tablets (7-11 inches) */
@media (min-width: 768px) and (max-width: 1024px) {
  .member-form-inputbox,
  .member-form-photo {
    font-size: 14px;
    padding: 10px;
  }

  .member-form-button {
    font-size: 16px;
    padding: 10px 15px;
  }

  .add-member-details .photo-preview {
    width: 80px;
    height: 80px;
  }

  .add-member-details .member-list th,
  .add-member-details .member-list td {
    padding: 10px;
  }
}

/* For laptops (11-18 inches) */
@media (min-width: 1025px) and (max-width: 1440px) {
  .member-form-inputbox,
  .member-form-photo {
    font-size: 16px;
    padding: 12px;
  }

  .member-form-button {
    font-size: 18px;
    padding: 12px 18px;
  }

  .add-member-details .photo-preview {
    width: 100px;
    height: 100px;
  }

  .add-member-details .member-list th,
  .add-member-details .member-list td {
    padding: 12px;
  }
}

/* For larger screens (above 32 inches) */
@media (min-width: 1441px) {
  .member-form-inputbox,
  .member-form-photo {
    font-size: 18px;
    padding: 15px;
  }

  .member-form-button {
    font-size: 20px;
    padding: 15px 20px;
  }

  .add-member-details .photo-preview {
    width: 120px;
    height: 120px;
  }

  .add-member-details .member-list th,
  .add-member-details .member-list td {
    padding: 15px;
  }
}
