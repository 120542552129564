.team-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    margin: 10px;
    width: 250px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .team-card img {
    max-width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .view-team-details-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .view-team-details-btn:hover {
    background-color: #0056b3;
  }
  