.container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.header {
    text-align: center;
    margin-bottom: 20px;
}

.form-container {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 5px;
}

/* input {
    width: 100%;
    padding: 8px;
    margin-bottom: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
} */
.form-container input[type="text"],
.form-container input[type="email"],
.form-container input[type="password"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 1em;
    text-align: left;
    transition: border-color 0.3s ease;
}

/* Input focus state */
.form-container input[type="text"]:focus,
.form-container input[type="email"]:focus,
.form-container input[type="password"]:focus {
    border-color: #007bff;
    outline: none;
}

small {
    color: #888;
}

.error {
    color: red;
    font-size: 12px;
}

.success {
    color: green;
    font-size: 12px;
}

.button-group {
    display: flex;
    gap: 10px;
}

button {
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

button.outline {
    background: transparent;
    border: 1px solid #ccc;
}
.success {
    color: green;
}

.error {
    color: red;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* On top of everything */
}

.loading-text {
    color: white;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}
