/* General Styling */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f0f2f5;
  color: #333;
}

.event-body {
  width: 100%;
  display: flex;
  justify-content: center;
}

.event-container {
  margin-top: 20px;
  margin-bottom: 80px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 1200px;
}

.event-header,
.event-row {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr 1fr ;
  gap: 16px;
  padding: 16px;
  align-items: center;
}

.event-header {
  background-color: #FF4500;
  color: #ffffff;
  border-radius: 8px 8px 0 0;
}

.event-header div {
  font-weight: 600;
}

.event-row {
  border-bottom: 1px solid #e0e0e0;
}

.event-row:last-child {
  border-bottom: none;
}

.event-row div {
  padding: 8px;
}

.event-columns {
  text-align: center;
}

.event-name {
  text-align: left;
  padding-left: 16%;
}

.event-row:hover {
  background-color: #f4f4f4;
  transition: background-color 0.3s ease;
}
.file-icon {
  font-size: 24px;
  color: #e74c3c; /* Red color for PDF icon */
  cursor: pointer;
}

.file-icon:hover {
  color: #c0392b; /* Darker red on hover */
}

.know-more-button {
  background-color: #28a745; /* Green background */
  color: white; /* White text */
  padding: 10px 20px; /* Padding around the button */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 16px; /* Font size */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.know-more-button:hover {
  background-color: #218838; /* Darker green on hover */
}

/* Event.css */

.event-video {
  width: 100%; /* Ensure the video takes up the full width of the container */
  max-width: 800px; /* Optional: Limit the maximum width of the video */
  margin: 20px auto; /* Center the video and add some space above and below */
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow to the container */
  background-color: #fff; /* Optional: Set a background color */
  border-radius: 10px; /* Optional: Add rounded corners */
}

.video-container {
  position: relative; /* Position the video container relative to the parent */
 
  overflow: hidden; /* Hide any overflow content */
  border-radius: 10px; /* Match the border-radius of the parent container */
}

.video-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer; /* Change cursor to pointer when hovering over the video */
}

.custom-video {
  width: 100%; /* Ensure the video fills the entire container */
  height: 100%; /* Ensure the video fills the entire container */
  object-fit: cover; /* Cover the container, cropping the video if necessary */
  border-radius: 10px; /* Match the border-radius of the parent container */
}

.play-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px; /* Match the border-radius of the parent container */
}

.play-button {
  width: 50px;
  height: 50px;
  background-color: #fff;
  clip-path: polygon(25% 20%, 75% 50%, 25% 80%); /* Creates a triangle shape for the play button */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.play-button:hover {
  background-color: #f0f0f0; /* Change the color slightly on hover */
}

/* Event.css */

/* Button container */
.button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
}

/* Button styling */
.btn {
  padding: 10px 20px;
  background-color: #FF4500;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #0056b3;
}


@media (min-width: 931px) and (max-width: 1064px) {
  .know-more-button {
    font-size: 12px;
  }
}
@media (min-width: 769px) and (max-width: 931px) {
  .know-more-button {
    font-size: 6px;
  }
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .event-container {
    padding: 10px;
  }

  .event-header,
  .event-row {
    grid-template-columns: 1fr 2fr 1fr;
  }

  .event-name {
    padding-left: 10%;
  }
}

@media (max-width: 480px) {
  .event-header,
  .event-row {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .event-name {
    padding-left: 0;
    text-align: center;

  }

  .event-header div,
  .event-row div {
    padding: 8px 0;
  }
}
