/* Reset and Box Sizing */
* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.home-home {
  border-top: 10px solid #FF4500;
  background-color: #e7e7e7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.heading-container {
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #e7e7e7;
  width: 100%;
  color: white;
}

.content {
  width: 100%;
  margin: 0 auto;
  background-color: rgb(243 237 237 / 80%);
}

.body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading {
  margin: 0;
  font-size: 3em;
  color: #040404;
}

.slider-container {
  width: 100%;
  position: relative;
  /* background-color: aqua; */
  height: 79vh;
}

.slide {
  position: relative;
  height: 79vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slide-text {
  position: absolute;
  bottom: 10px;
  left: 20px;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 1;
}

.slider-overlay {
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  height: 100%;
  background: rgba(0, 0, 0, 0.616);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.website-name {
  color: white;
  font-size: 4.5em;
  text-align: center;
}

.slider-upcomingEvent-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin-bottom: 30px;
  color: white;
}

.other-content {
  background: #f0f0f0;
  margin-bottom: 60px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.event-aboutMba {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

.home-upcoming-events {
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #f8f8f8;
  box-sizing: border-box;
}

.home-about-mba {
  width: 100%;
  background-color: #f0f0f0;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
}

.home-about-mba h1 {
  font-size: 2.5em;
  color: #333;
}

.home-about-mba p {
  font-size: 1.25em;
  color: #666;
  line-height: 1.6;
  text-align: justify;
}

.home-coaching-content {
  width: 100%;
}

.video-description-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 60px;
  margin-bottom: 60px;
}

.video {
  width: 100%;
}

.description {
  width: 100%;
  text-align: center;
  font-size: large;
  text-align: justify;
}

/* Media Queries for Smaller Screens */
@media (min-width: 768px) {
  .slider-upcomingEvent-container {
    flex-direction: row;
  }

  .event-aboutMba {
    flex-direction: row;
    justify-content: space-between;
  }

  .home-upcoming-events {
    width: 35%;
    margin-right: 5%;
  }

  .home-about-mba {
    width: 55%;
  }

  .video-description-content {
    flex-direction: row;
  }

  .video {
    width: 60%;
  }

  .description {
    width: 40%;
  }
}

@media (min-width: 768px) and (max-width: 1200px){
  .website-name {
    font-size: 2.75em;
  }

  .slider-overlay h3{
    font-size: 1em;
  }
  .slider-container,
  .slide,
  .slider-overlay {
    height: 60vh;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .slider-upcomingEvent-container {
    flex-direction: column;
  }

  .slider-container,
  .slide,
  .slider-overlay {
    height: 60vh;
  }
  .website-name {
    font-size: 1.72em;
  }

  .slider-overlay h3{
    font-size: 1em;
  }
  .event-aboutMba {
    flex-direction: column;
  }

  .home-upcoming-events {
    width: 100%;
    margin-bottom: 20px;
  }

  .home-about-mba {
    width: 100%;
    font-size: 1em;
  }

  .video-description-content {
    flex-direction: column;
  }

  .video {
    width: 100%;
  }

  .description {
    width: 100%;
  }
}

@media (max-width: 479px) {
  .slider-container,
  .slide,
  .slider-overlay {
    height: 40vh;
  }

  .website-name {
    font-size: 1.2em;
  }

  .slider-overlay h3{
    font-size: 0.5em;
  }

  .home-about-mba h1 {
    font-size: 1.5em;
  }

  .home-about-mba p {
    font-size: 0.9em;
  }
}
