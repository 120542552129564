/* General Navbar Styling */
nav {
  width: 100%;
  background-color: #e7e7e7;
  padding: 10px 0;
  z-index: 1000; /* Ensure the navbar is on top */
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.nav-bar {
  transition: height 0.3s ease; /* Smooth height transition */
}

.navbar-logo {
  margin-left: 20px;
}

.navbar-logo img {
  height: 80px; /* Adjust as needed */
}

nav .nav-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

nav .nav-links li {
  padding: 10px 20px;
  position: relative; /* Necessary for the dropdown menu */
  font-size: large;
}

nav .nav-links li a {
  color: rgb(3, 3, 3);
  font-size: large;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  display: block;
}

nav .nav-links li a:hover {
  background-color: #a8a8a8;
}

/* Dropdown Menu Styling */
.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%; /* Position below the parent menu item */
  left: 0;
  background-color: #e7e7e7; /* Match the navbar background color */
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 200px; /* Adjust as needed */
  z-index: 1001;
}

.dropdown-menu li {
  padding: 0;
}

.dropdown-menu li a {
  padding: 10px 20px;
  text-align: left; /* Align text to the left */
  background-color: #e7e7e7; /* Match the dropdown items background with the navbar */
}

.dropdown-menu li a:hover {
  background-color: #a8a8a8; /* Hover color to match navbar hover */
}

nav .nav-links li:hover .dropdown-menu {
  display: block; /* Show the dropdown menu on hover */
}

.dropdown-menu li.button-item {
  display: flex;
  justify-content: center;
  align-items: center;
}



/* navbar.css */

.username-display {
  margin-left: auto;
  padding-right: 20px;
  font-weight: bold;
  /* color: #fff; */
  margin-right: 3%;
}

.dropdown-menu li button {
  /* color: #fff; */
  text-decoration: none;
  display: block;
  width: 100%;
  /* background: none; */
  border: none;
  cursor: pointer;
}



/* Mobile Menu Toggle */
/* .mobile-menu-toggle {
  display: none;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 20px;
} */
.mobile-menu-toggle {
  display: none;
  font-size: 20px; /* Reduce the font size to make it smaller */
  background: none;
  border: none;
  cursor: pointer;
  margin-left: auto; /* Pushes the button to the right */
  padding: 5px; /* Add padding for better touch area */
}

.nav-logoutbutton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0; /* Remove any padding */
}


@media (max-width: 900px) {
  .mobile-menu-toggle {
    display: block;
  }

  nav .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
  }

  nav .nav-links.show {
    display: flex;
  }

  .nav-bar.show {
    height: auto; /* Allow the height to adjust automatically */
  }

  nav .nav-links li {
    padding: 10px;
    width: 100%;
  }

  nav .nav-links li a {
    text-align: left;
    padding: 10px 20px;
  }

  .dropdown-menu {
    position: relative;
    width: 100%;
  }

  .dropdown-menu li a {
    padding: 10px;
  }
  .mobile-menu-toggle {
    font-size: 18px; /* Further reduction in size if needed */
    margin-right: 10px; /* Adjust margin to position it next to the logo */
  } 
}

@media (max-width: 480px) {
  .navbar-logo img {
    height: 40px; /* Adjust as needed */
  }

  nav .nav-links li {
    padding: 8px;
  }

  nav .nav-links li a {
    padding: 8px 16px;
  }
}
