/* Scoping all styles under a unique class - .team-registration-form-container */
.team-registration-form-container {
    margin-top: 20px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .team-registration-form-container h1,
  .team-registration-form-container h3 {
    font-family: 'Arial', sans-serif;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .team-registration-form-container form {
    display: flex;
    flex-direction: column;
  }
  
  /* Styling long text fields */
  .team-registration-form-container .team-retistration-long-text-field {
    margin-bottom: 20px;
  }
  
  .team-registration-form-container .team-retistration-long-text-field label {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }
  
  .team-registration-form-container .team-retistration-long-text-field input,
  .team-registration-form-container .team-retistration-long-text-field textarea {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    transition: border 0.3s ease;
  }
  
  .team-registration-form-container .team-retistration-long-text-field input:focus,
  .team-registration-form-container .team-retistration-long-text-field textarea:focus {
    border-color: #007bff;
  }
  
  /* Styling short text fields */
  .team-registration-form-container .team-retistration-short-text-field {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    width: 48%;
  }
  
  /* Aligning two consecutive short text fields in one line */
  .team-registration-form-container .team-retistration-short-text-field + .team-retistration-short-text-field {
    display: inline-block;
    width: 48%;
    margin-right: 4%;
  }
  
  .team-registration-form-container .team-retistration-short-text-field:last-of-type {
    margin-right: 0;
  }
  
  .team-registration-form-container .team-retistration-short-text-field label {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }
  
  .team-registration-form-container .team-retistration-short-text-field input {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    transition: border 0.3s ease;
  }
  
  .team-registration-form-container .team-retistration-short-text-field input:focus {
    border-color: #007bff;
  }
  
  /* Styling the file input */
  .team-registration-form-container input[type="file"] {
    padding: 5px;
    font-size: 1rem;
    margin-top: 5px;
  }
  
  /* Submit Button */
  .team-registration-form-container .team-register-submit-button {
    background-color: #007bff;
    color: #fff;
    font-size: 1.1rem;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;
  }
  
  .team-registration-form-container .team-register-submit-button:hover {
    background-color: #0056b3;
  }
  

  .team-register-display-flex{
    display: flex;
    flex-direction: row; /* This makes the content display horizontally */
    justify-content: space-between; /* Optional: This distributes the items evenly */
    align-items: center; /* Optional: This vertically aligns items in the center */
    gap: 20px; 
  }

  .team-registration-photo-preview{
    height: 40px;
    width: 40px;
}

/* Adjust dropdown positioning */
.react-datepicker__month-dropdown-container,
.react-datepicker__year-dropdown-container {
  /* position: absolute; */
  padding-top: 20px; 
  /* right: 0;  
  z-index: 2; */
}


/* .react-datepicker__month-dropdown-container {
  width: 80px; 
}

.react-datepicker__year-dropdown-container {
  width: 90px; 
}

.react-datepicker__navigation--previous {
  left: 10px;  
}

.react-datepicker__navigation--next {
  right: 10px; 
} */
