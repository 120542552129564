.display-home {
    display: flex;
    flex-direction: column;
    height: 100vh;
    font-family: "Orbitron", sans-serif;;

  }
  
  /* Header */
  .display-header {
    height: 16vh;
    background-color: #4B5563;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
  
  .matchNo-box {
    display: flex;
    align-items: center;
    width: 15vw;
    height: 80%;
  }
  
  .matchNo {
    background-color: #D1D5DB;
    height: 100%;
    width: 100%;
    margin-left: 1.25rem;
    text-align: center;
  }
  
  .matchNo-text{
    font-size: 1.5rem;
    
  }
  
  .matchName-box {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center; 
    background-color: #D1D5DB;
    width: 60vw;
    height: 60%;
  }
  
  .matchName {
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  
  .matchName-text {
    font-weight: bold;
  }
  
  .logo-outer-box {
    display: flex;
    align-items: center;
    width: 15vw;
    height: 60%;
  }
  
  .logo-box {
    height: 100%;
    width: 100%;
    margin-right: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .logo {
    width: 4.5rem;
    height: 4.5rem;
  }
  
  /* Body */
  .display-body {
    display: flex;
    background-color: #E5E7EB;
    height: 84vh;
  }
  
  .home-away-team {
    width: 33.33vw;
    border: 1px solid #2D3748;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2%; 
  }

  .home-away-team > *:last-child {
    margin-bottom: 0;
  }
  
  .team-name-box {
    background-color: #D1D5DB;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  
  .team-name {
    font-size: 3rem;
    font-weight: bold;
    margin: 4%;
  }
  
  .score-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .score-text {
    font-size: 1.25rem;
    margin-top: 1.25rem;
    font-weight: 900;
  }
  
  .score-box {
    background-color: #D1D5DB;
    width: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3.5rem;
  }
  
  .score {
    font-size: 3rem;
    font-weight: 1000;
  }
  
  .team-out-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .team-out-text {
    font-size: 1.25rem;
    margin-top: 1rem;
  }
  
  .team-out-box {
    background-color: #D1D5DB;
    width: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
  }
  
  .team-out {
    font-size: 2rem;
  }
  
  .team-foul-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .team-foul-text {
    font-size: 1.25rem;
    margin-top: 1rem;
  }
  
  .team-foul-box {
    background-color: #D1D5DB;
    width: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
  }
  
  .team-foul {
    font-size: 2rem;
  }
  
  .fo-container {
    width: 90%;
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5rem;
  }
  
  .player-fo-container-left {
    width: 40%;
    height: 5rem;
    margin-left: 1.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .player-fo-container-right {
    width: 40%;
    height: 5rem;
    margin-right: 1.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .player-fo-text {
    font-size: 1.15rem;
  }
  
  .player-fo-box {
    width: 40%;
    height: 3rem;
    background-color: #D1D5DB;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .player-fo {
    font-size: 2rem;
    margin: 2px;
  }
  
  .center-gap {
    width: 20%;
    height: 2rem;
  }

  .timer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  .timer-text {
    font-size: 2rem;
    margin-top: 1.25rem;
    font-weight: 900;
  }
  
  .timer-box {
    background-color: #D1D5DB;
    width: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 50%; */
    width: 70%;
  }
  
  .timer {
    font-size: 2.75rem;
    font-weight: 1000;
  }
  
  .timer-period{
    font-size: 2.rem;
    font-weight: 900;
  }

  .period-box {
    background-color: #D1D5DB;
    width: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30%;
    width: 30%;
  }


.display-back-button {
  margin-bottom: 20px;
}

.custom-back-button {
  padding: 8px 16px;
  background-color: #4CAF50; 
  color: white; 
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.custom-back-button:hover {
  background-color: #45a049; 
}
  
  .serving-arrow-container {
    width: 90%;
    /* margin-top: 1.5rem; */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5rem;
  }
  
  .serving-arrow-container-left {
    width: 40%;
    height: 5rem;
    margin-left: 1.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .serving-arrow-container-right {
    width: 40%;
    height: 5rem;
    margin-right: 1.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .serving-arrow-text {
    font-size: 26px; /* Adjust the arrow size as needed */
    cursor: pointer; /* Optional: makes it clear the arrows can be clicked */
    transition: color 0.3s ease, text-shadow 0.3s ease;
}

.glow {
    color: #ff0; /* Adjust the glow color as needed */
    text-shadow: 0 0 10px #ff0, 0 0 20px #ff0, 0 0 30px #ff0, 0 0 40px #ff0, 0 0 50px #ff0, 0 0 60px #ff0, 0 0 70px #ff0;
}
  
  .serving-arrow-center-gap {
    width: 20%;
    height: 2rem;
  }

  
  
  /* Media Queries */
  @media screen and (max-width: 1440px) {
    .matchNo-box, .logo-outer-box, .matchName-box {
      margin: 1rem;
    }
    
    .team-name-box, .score-box, .team-out-box, .team-foul-box, .player-fo-box {
      margin: 0.75rem;
    }
  }
  
  @media screen and (max-width: 1280px) {
    .matchNo-box, .logo-outer-box, .matchName-box {
      margin: 0.75rem;
    }
    
    .team-name-box, .score-box, .team-out-box, .team-foul-box, .player-fo-box {
      margin: 0.5rem;
    }
  }
  
  @media screen and (max-width: 1024px) {
    .matchNo-box, .logo-outer-box, .matchName-box {
      margin: 0.5rem;
    }
    
    .team-name-box, .score-box, .team-out-box, .team-foul-box, .player-fo-box {
      margin: 0.25rem;
    }
  }
  