.container {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
}

.header {
    text-align: center;
    margin-bottom: 30px;
}

.form-container {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}
/* Styles for the button group container */
.button-group {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
}

/* Base styles for buttons within the button group */
.button-group button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
}

/* Hover effect for buttons within the button group */
.button-group button:hover {
    background-color: #0056b3;
}

/* Specific styles for outline buttons within the button group */
.button-group button.outline {
    background-color: #fff;
    color: #007bff;
    border: 1px solid #007bff;
}

/* Hover effect for outline buttons within the button group */
.button-group button.outline:hover {
    background-color: #007bff;
    color: #fff;
}

/* Style for read-only input fields */
.form-group input[readonly] {
    background-color: #e0e0e0; /* Light grey background */
    color: #7a7a7a; /* Grey text color */
    border: 1px solid #ccc; /* Light grey border */
    cursor: not-allowed; /* Change cursor to indicate non-editable */
}
