/* General Styles */
.event-detail-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  margin-top: 0;
  width: 100vw;
}

.event-detail-content {
  width: 90%; /* Default width for mobile */
  max-width: 800px; /* Max width */
  text-align: justify;
  line-height: 1.6;
  color: #333;
  background-color: rgb(249 237 237 / 80%);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.section {
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.section-title {
  font-size: 1.6em;
  margin-bottom: 15px;
  color: #333;
  border-bottom: 2px solid #FF4500;
  padding-bottom: 5px;
}

.section p {
  margin: 10px 0;
  line-height: 1.6;
}

.section p strong {
  color: #FF4500; 
}

.guidelines {
  background-color: #e9ecef;
}

.checkbox-group {
  margin-bottom: 20px;
}

.checkbox-group label {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.checkbox-group input[type="checkbox"] {
  margin-right: 10px; /* Add space between checkbox and text */
}

.register-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.register-button:disabled {
  background-color: #c6c6c6;
  cursor: not-allowed;
}

.register-button:hover:not(:disabled) {
  background-color: #0056b3;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .event-detail-content {
      width: 95%; /* Take more width on mobile */
      padding: 15px; /* Reduce padding */
  }
  
  .section-title {
      font-size: 1.4em; /* Slightly smaller titles */
  }
  
  .register-button {
      width: 100%; /* Full-width button */
  }
}

/* Tablets and Small Laptops */
@media (min-width: 769px) and (max-width: 1024px) {
  .event-detail-content {
      width: 80%; /* Moderate width */
      padding: 20px; /* Normal padding */
  }

  .section-title {
      font-size: 1.5em; /* Slightly smaller than desktop */
  }
}

/* Laptops */
@media (min-width: 1025px) and (max-width: 1366px) {
  .event-detail-content {
      width: 70%; /* More width for larger screens */
  }

  .section-title {
      font-size: 1.6em; /* Maintain desktop size */
  }
}

/* Large Screens */
@media (min-width: 1367px) {
  .event-detail-content {
      width: 60%; /* Center on very large screens */
  }

  .section-title {
      font-size: 1.8em; /* Larger titles */
  }
}
