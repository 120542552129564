.academy-sub-container{
    display: flex;
  align-items: center; 
  gap: 40px;
    margin-bottom: 40px;
}

.academy-sub-content{
    width: 60%;
    text-align: center;
    justify-content: center;
    text-align: justify; 
    
}

.academy-image{
    width: 40%;
    text-align: center;
}

/* .academy-img{
    width: 100%;
    height: 100%;
} */

.academy-img img  {
    /* width: 100%;   */
    height: 80vh;
    object-fit: cover; 
  }