.event-gallery-container {
  padding: 20px;
  text-align: center;
}

.event-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 20px;
  margin-bottom: 60px;
}

.event-photo-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.event-photo-item img {
  width: 100%;
  height: auto;
  max-height: 200px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  object-fit: cover;
  transition: transform 0.3s ease;
}

.event-photo-item img:hover {
  transform: scale(1.05);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.enlarged-image {
  max-width: 90%;
  max-height: 90%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .event-gallery {
    grid-template-columns: repeat(2, 1fr);
  }
  /* event-photo-item img{
    height: 50%;
  } */
}

@media (max-width: 480px) {
  .event-gallery {
    grid-template-columns: 1fr;
  }

  .event-photo-item img {
    max-height: 150px;
  }
}
