.home-coaching-heading {
  text-align: center; /* Center the heading text */
  margin-bottom: 20px; /* Add some space below the heading */
  /* font-size: 3em; */
}

.home-coaching-heading h1 {
  font-size: 2em;
}

.home-coaching-cards {
  display: flex; /* Use flexbox to arrange cards in a row */
  justify-content: space-between; /* Space between cards */
  flex-wrap: wrap; /* Allow wrapping to new lines */
}

.training-card {
  background-color: #f9f9f9; /* Background color for the card */
  padding: 20px; /* Padding inside the card */
  border: 1px solid #ddd; 
  border-radius: 5px; 
  width: 23%; /* Each card takes approximately 23% of the container width */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for the card */
  text-align: center; /* Center the card content */
  margin-bottom: 20px; /* Add space between rows of cards */
}

.training-card img {
  width: 100%; 
  height: 50%; 
  border-radius: 5px 5px 0 0; 
}

.training-card h3 {
  margin: 10px 0; /* Space above and below the heading */
  font-size: 1.2em; /* Font size for the heading */
  height: 10%;
}

.training-card p {
  font-size: 1em; /* Font size for the description */
  color: #555; /* Text color for the description */
  height: 20%; 
}

.learn-more-button {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 10px;
  background-color: #007bff; /* Button background color */
  color: white; /* Button text color */
  text-decoration: none; /* Remove underline from text */
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.3s; /* Smooth background color transition */
}

.learn-more-button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

/* Media Queries for Smaller Screens */
@media (max-width: 1200px) {
  .training-card {
      width: 30%; /* Adjust card width for larger screens */
  }

}

@media (max-width: 992px) {
  .training-card {
      width: 45%; /* Adjust card width for medium screens */
  }
}

@media (max-width: 768px) {
  .training-card {
      width: 100%; /* Adjust card width for small screens */
      height: 50%;
  }

  .home-coaching-heading h1 {
      font-size: 1.5em;
  }

  .training-card h3 {
      font-size: 1.1em;
      height: 10%; 
  }

  .training-card p {
      font-size: 0.9em;
      height: 20%; 
  }

  .learn-more-button {
      font-size: 0.9em;
      padding: 8px 16px;
      height: 10%;
  }
}
