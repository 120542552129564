/* Base styles */
.container {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    width: 100%;
}

.header {
    text-align: center;
    margin-bottom: 30px;
}

.form-container {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.button-group {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    flex-wrap: wrap; /* Allows buttons to wrap on smaller screens */
}

button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
    flex-grow: 1; /* Makes buttons grow equally */
    text-align: center;
}

/* Target buttons within the .button-group */
.button-group button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button-group button:hover {
    background-color: #0056b3;
}

/* Specific styles for outline buttons within the .button-group */
.button-group button.outline {
    background-color: #fff;
    color: #007bff;
    border: 1px solid #007bff;
}

.button-group button.outline:hover {
    background-color: #007bff;
    color: #fff;
}


/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .container {
        max-width: 500px;
    }

    .button-group {
        justify-content: flex-start;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .container {
        max-width: 600px;
    }

    .form-container {
        padding: 30px;
    }

    .button-group {
        justify-content: flex-start;
    }

    button {
        flex-grow: 0;
        padding: 12px 20px;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .container {
        max-width: 700px;
    }
}

/* Extra small devices (phones, less than 768px) */
@media (max-width: 767.98px) {
    .container {
        padding: 15px;
    }

    .form-container {
        padding: 15px;
    }

    .button-group {
        flex-direction: column; /* Stack buttons vertically on small screens */
    }

    button {
        width: 100%;
    }
}
