.payment-validation-container {
    padding: 20px;
}

.payment-validation-table {
    width: 100%;
    border-collapse: collapse;
}

.payment-validation-table th,
.payment-validation-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.payment-validation-table th {
    background-color: #f2f2f2;
}

.payment-validation-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.payment-validation-table tr:hover {
    background-color: #f1f1f1;
}



/* success page, pagination css */
.pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.page-button {
    padding: 8px 12px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    color: black;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.page-button:hover {
    background-color: #e0e0e0;
}

.page-button.active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
}
