.upcommingEvent-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.upcommingEvent-body h1 {
  font-size: 2em;
  text-align: center;
}

.gameday-container,
.gameday {
  width: 100%;
  align-items: center;
  height: auto;
}

/* .nextEvent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #000000;
  border: 1px solid #ddd;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
}

.nextEvent-Name {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 10px;
  color: #a2ff4a;
  text-align: center;
}

.nextEvent-Time {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  color: #f4f3f3;
  margin-bottom: 20px;
  padding: 20px;
  background: #000000;
  border: 2px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.next-event-seconds {
  color: #FF4500;
}

.nextEvent-Time div {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nextEvent-Time span {
  font-size: 1em;
  color: #ffffff;
} */
.nextEvent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #000000;
  border: 1px solid #ddd;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
}

.nextEvent-Name {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #a2ff4a;
}

.nextEvent-Time {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 56px;
  color: #f4f3f3;
  margin-bottom: 20px;
  padding: 20px;
  background: #000000;
  border: 2px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.next-event-seconds{
  color: #FF4500;
}

.nextEvent-Time div {
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nextEvent-Time span {
  font-size: 20px;
  color: #ffffff;
}

.nextEvent-Register {
  display: inline-block;
  padding: 10px 20px;
  background-color: #a2ff4a;
  color: white;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  width: 90%;
  transition: background-color 0.3s;
  font-size: 1.2em;
  text-align: center;
}

.nextEvent-Register:hover {
  background-color: #11ff00;
}

.custom-link {
  text-decoration: none; /* Remove the underline */
  font-size: 1em; /* Adjust the font size */
  color: #333; /* Set the text color */
}

.later-Events .event {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  width: 100%;
  max-width: 500px;
  margin-bottom: 10px;
}

.event {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  width: 100%;
  max-width: 500px;
  margin-bottom: 10px;
}

.event-text,
.event-date {
  font-size: 1.5em;
  font-weight: bold;
}

.event-date {
  color: #ff4500;
}

@media(min-width:1701px){
  .upcommingEvent-body h1{
    font-size: 1.7em;
  }

  .nextEvent-Name{
    font-size: 1.7em;
    text-align: center;
  }

  .nextEvent-Time{
    font-size: 1.7em;
  }
}

@media(min-width: 1266px) and (max-width: 1700px){
  .upcommingEvent-body h1 {
    font-size: 1.5em;
    
}


.nextEvent-Name {
    font-size: 1.5em;
    text-align: center;
}

.nextEvent-Time {
    font-size: 1.5em;
    /* flex-direction: column; */
}

.nextEvent-Time div {
    margin: 5px 0;
    font-size: 1em;
    gap: 20px;
    margin-right: 10px;
}

.nextEvent-Time span {
    font-size: 0.8em;
}


}
/* Media Queries for Smaller Screens */
@media (max-width: 1265px) {
  .upcommingEvent-body h1 {
      font-size: 1.5em;
  }

  .nextEvent-Name {
      font-size: 1.5em;
      text-align: center;
  }

  .nextEvent-Time {
      font-size: 1.5em;
      flex-direction: column;
  }

  .nextEvent-Time div {
      margin: 5px 0;
      font-size: 1em;
  }

  .nextEvent-Time span {
      font-size: 0.8em;
  }

  .nextEvent-Register {
      font-size: 1em;
  }
}
