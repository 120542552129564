.timeline {
  display: flex;
  justify-content: space-between; /* Evenly distribute items */
  margin-top: 2rem;
  position: relative;
  padding: 20px;
  max-width: 1000px; /* Adjust according to your layout */
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.timeline-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex-grow: 1;
  text-align: center;
  flex-basis: 20%;
  height: 40vh;
}

.timeline-item:not(:last-child)::after {
  content: "";
  position: absolute;
  width: 100%; 
  height: 4px;
  background-color: #ddd;
  top: 50%;
  left: 50%;
  transform: translateX(50%); /* Adjust to sit in the center */
  z-index: -1; /* Behind the content */
}

.timeline-item.completed::after {
  background-color: #4caf50; /* Green color for completed stages */
}

.timeline-icon {
  background-color: #ddd;
  border-radius: 50%;
  padding: 10px;
  width: 40px;
  height: 40px;
  text-align: center;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline-item.completed .timeline-icon {
  background-color: #4caf50; /* Green for completed */
  color: white;
}

.timeline-content {
  margin-top: 10px;
  /* width: 80%;  */
  word-wrap: break-word; /* Ensure text breaks properly */
  min-height: 50px; /* Ensure consistent block height */
}

/* Adjust the line between items */
.timeline-item:not(:last-child)::after {
  left: 21%;
  width: calc(100% - 40px); 
  height: 4px;
  top: 6%; /*height from top*/
  background-color: #ddd;
  z-index: 0;
}

.timeline-item.completed::after {
  background-color: #4caf50;
}



/* Common button styles */
.resume-state button, 
.resume-state-payment-failed button {
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  color: white;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Button styles matching the timeline color */
.resume-state button {
  background-color: #4caf50; /* Timeline color */
}

.resume-state button:hover {
  background-color: #43a047; /* Slightly darker on hover */
  transform: scale(1.05);
}

/* Red button for retry payment */
.resume-state-payment-failed button {
  background-color: #f44336; /* Red color for Retry */
}

.resume-state-payment-failed button:hover {
  background-color: #d32f2f; /* Darker red on hover */
  transform: scale(1.05);
}

/* Styling for error message */
.resume-state-payment-failed .error-message {
  margin-top: 10px;
  color: #f44336;
  font-size: 14px;
  font-weight: bold;
}

/* Optional: To make buttons look consistent */
button:focus {
  outline: none;
}


/* Responsive styles for max-width 989px */
@media (max-width: 989px) {
  .timeline {
    flex-direction: column;
    padding: 10px;
  }

  .timeline-item {
    flex-basis: 100%;
    height: auto;
    margin-bottom: 20px;
  }

  .timeline-item:not(:last-child)::after {
    width: 4px;
    height: calc(100% - 90%);
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  .timeline-content {
    margin-top: 10px;
  }
}

/* Responsive styles for max-width 600px */
@media (max-width: 600px) {
  .timeline {
    padding: 10px;
  }

  .timeline-icon {
    width: 35px;
    height: 35px;
    padding: 8px;
  }

  .resume-state button,
  .resume-state-payment-failed button {
    font-size: 14px;
    padding: 8px 15px;
  }

  .timeline-content h3 {
    font-size: 16px;
  }
  .timeline-item:not(:last-child)::after {
    width: 4px;
    height: calc(100% - 60%);
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}

/* Responsive styles for max-width 400px */
@media (max-width: 400px) {
  .timeline {
    padding: 5px;
  }

  .timeline-icon {
    width: 30px;
    height: 30px;
    padding: 6px;
  }

  .resume-state button,
  .resume-state-payment-failed button {
    font-size: 12px;
    padding: 6px 10px;
  }

  .timeline-content h3 {
    font-size: 14px;
  }

  .resume-state-payment-failed .error-message {
    font-size: 12px;
  }
}