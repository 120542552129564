/* Base styles */
.age-category-page {
  display: flex;
  flex-direction: column; /* Default to column layout on smaller screens */
  padding: 20px;
}

.add-age-category,
.age-category-list {
  width: 100%; /* Full width on small screens */
  margin-bottom: 20px; /* Space between sections */
}

.add-age-category h2,
.age-category-list h2 {
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
}

.age-category-page form div {
  margin-bottom: 15px;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.age-category-page input,
select {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Scoped button styles for AgeCategory page */
.age-category-page .submit-button {
  padding: 10px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.age-category-page .submit-button:hover {
  background-color: #0056b3;
}

.age-category-page .delete-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.age-category-page .delete-button:hover {
  background-color: #cc0000;
}

/* Scoped confirmation popup button styles */
.age-category-page .confirmation-popup button {
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 10px;
}

.age-category-page .confirmation-popup button.confirm {
  background-color: #007bff;
  color: white;
}

.age-category-page .confirmation-popup button.confirm:hover {
  background-color: #0056b3;
}

.age-category-page .confirmation-popup button.cancel {
  background-color: #ccc;
  color: black;
}

.age-category-page .confirmation-popup button.cancel:hover {
  background-color: #999;
}

.age-category-list table {
  width: 100%;
  border-collapse: collapse;
}

.age-category-list th,
.age-category-list td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.age-category-list th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.age-category-list td {
  vertical-align: middle;
}

/* Media Queries for Responsive Design */

/* Mobile screens from 300px to 600px */
@media (min-width: 300px) and (max-width: 600px) {
  .age-category-page {
    padding: 15px; /* Adjust padding for smaller screens */
  }

  .add-age-category,
  .age-category-list {
    width: 100%; /* Ensure full width */
    margin-bottom: 20px; /* Space between sections */
  }

  .age-category-page .submit-button,
  .age-category-page .delete-button {
    font-size: 14px; /* Smaller font size for buttons */
    padding: 8px; /* Adjust padding */
  }

  .age-category-list th,
  .age-category-list td {
    font-size: 14px; /* Smaller font size for table cells */
    padding: 8px; /* Adjust padding */
  }

  form input,
  form select {
    font-size: 14px; /* Smaller font size for inputs */
  }
}

/* Tablets and small screens */
@media (min-width: 600px) {
  .age-category-page {
    flex-direction: row; /* Row layout on larger screens */
    justify-content: space-between; /* Space between the sections */
  }

  .add-age-category,
  .age-category-list {
    width: 48%; /* Adjust width for side-by-side layout */
    margin-bottom: 0; /* Remove bottom margin for side-by-side layout */
  }
}

/* Laptops and larger screens */
@media (min-width: 992px) {
  .age-category-page {
    padding: 40px; /* Increase padding on larger screens */
  }

  .age-category-page .submit-button,
  .age-category-page .delete-button {
    font-size: 18px; /* Larger font size for buttons */
  }
}

/* Large displays */
@media (min-width: 1200px) {
  .age-category-page {
    padding: 60px; /* Increase padding on very large screens */
  }
}

  /* .popup-content button {
    padding: 10px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 10px;
  }
  
  .popup-content button:first-of-type {
    background-color: #007bff;
    color: white;
  }
  
  .popup-content button:first-of-type:hover {
    background-color: #0056b3;
  }
  
  .popup-content button:last-of-type {
    background-color: #ccc;
    color: black;
  }
  
  .popup-content button:last-of-type:hover {
    background-color: #999;
  }
   */