.academy-container {
    display: flex;
    justify-content: center;
    align-items: flex-start; 
    /* min-height: calc(100vh - 60px - 50px);   */
    padding: 20px;
    margin-top: 0; /* Ensure there's no margin at the top */
  }

  .academy-content {
    width: 100%;
    
    text-align: justify;
    font-size: 1.5em; /* Increase text size */
    line-height: 1.6; /* Increase line height for readability */
    color: #333; /* Text color */
   background-color: rgb(249 237 237 / 80%);
   /* background-color:#e7e7e7; */
    color: rgb(0, 0, 0);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: add a shadow for better contrast */
    text-align: center;
    text-align: justify; 
    justify-content: center;
  }