
/* .custom-datepicker {
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  font-size: 16px;
}

.react-datepicker__day--selected {
  background-color: #4CAF50;
  color: white;
} */
.custom-datepicker {
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  max-width: 300px; /* Limit maximum width for larger screens */
  font-size: 16px;
  box-sizing: border-box; /* Ensure padding is included in width */
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .custom-datepicker {
    max-width: 90%; /* Make it take up to 90% of the viewport width */
    font-size: 14px; /* Slightly reduce font size for smaller screens */
  }
}

/* Adjustments for laptops */
@media (min-width: 769px) and (max-width: 1366px) {
  .custom-datepicker {
    max-width: 80%; /* Make it take up to 80% of the viewport width */
    font-size: 15px; /* Slightly adjust font size */
  }
}

/* Adjustments for larger screens */
@media (min-width: 1367px) {
  .custom-datepicker {
    max-width: 400px; /* Increase max-width for larger screens */
  }
}

.react-datepicker {
  width: 100% !important; /* Ensure datepicker calendar takes full width */
  max-width: none !important; /* Remove any max-width restrictions */
}

/* Prevent calendar from moving out of viewport on smaller screens */
.react-datepicker__triangle {
  left: 50% !important; /* Center the triangle */
  transform: translateX(-50%);
}


/* Adjust the calendar for smaller displays */
@media (max-width: 768px) {
  .react-datepicker {
    font-size: 14px; /* Reduce font size for smaller displays */
  }
}

/* Ensure calendar stays within the viewport */
.react-datepicker__month {
  max-width: 300px; /* Set max-width for the calendar dropdown */
}

/* Keep the calendar from going outside the screen on smaller displays */
.react-datepicker__month-container {
  left: 0 !important; /* Align to the left to avoid overflow */
  right: auto !important; /* Disable any auto right alignment */
}


.player-registration-form-label{
  border: none;
}


.player-detail-form-container {
  /* width: 100%; */
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  margin-bottom: 20px;
}

.player-detail-form-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.player-detail-full-length-textbox {
  width: 100%;
  margin-bottom: 15px;
}

.player-detail-full-length-textbox input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.player-details-display-flex {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 15px;
}

.player-detail-half-length-textbox {
  width: 48%; /* Takes half the width of the container */
}

.player-detail-half-length-textbox input,
.player-detail-half-length-textbox select,
.player-detail-half-length-textbox label {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.player-detail-half-length-textbox label {
  display: flex;
  align-items: center;
}

.player-detail-half-length-textbox label input[type="checkbox"] {
  margin-right: 10px;
  width: 20%;
}

.player-detail-check-box{
  width: 20%;
  margin-left: 20%;
}

.review-players-table {
  width: 100%;
  border-collapse: collapse;
}

.review-players-table th,
.review-players-table td {
  padding: 10px;
  text-align: left;
}





.review-players {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.review-players {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.review-players h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.player-card {
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.player-card:hover {
  transform: scale(1.05);
}

.player-card div {
  margin-bottom: 8px;
}

.player-card strong {
  font-weight: 600;
  color: #333;
}

.player-card .player-photo {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-top: 10px;
}

.remove-button {
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #ff4d4d;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.remove-button:hover {
  background-color: #e60000;
}





.player-register-review-player-details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
}
@media (max-width:950px) {
  .player-register-review-player-details {
      grid-template-columns: repeat(2, 1fr); /* Change to 2 columns */
  }
}

/* Media query for max-width 380px */
@media (max-width: 600px) {
  .player-register-review-player-details {
      grid-template-columns: 1fr; /* Change to 1 column */
  }
}

.player-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  text-align: center;
}
.player-photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-top: 10px;
  border: 2px solid #ccc;
  background-color: #f0f0f0; /* Light grey background in case the image is broken */
}


.player-card:hover {
  transform: scale(1.05);
}

.player-card div {
  margin-bottom: 8px;
}

.player-card div {
  display: flex;
  /* justify-content: space-between; */
  overflow: hidden;
  white-space: nowrap; /* Prevent line wrapping */
  text-overflow: ellipsis; /* Adds three dots for overflowed content */
  height: 20px; /* Adjust to ensure one line per div */
}

.player-card .player-register-player-address {
  height: 57px; /* Three lines for the address */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal; /* Allow wrapping for multiple lines */
  text-overflow: ellipsis;
}

.player-card strong {
  font-weight: 600;
  color: #333;
}

.player-photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-top: 10px;
  border: 2px solid #ccc;
}

.player-registrtion-add-submit-button {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.remove-button {
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #ff4d4d;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.remove-button:hover {
  background-color: #e60000;
}

.add-more-btn, .submit-btn {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.add-more-btn:hover {
  background-color: #45a049;
}

.submit-btn:hover {
  background-color: #45a049;
}

.add-more-btn {
  background-color: #2196F3;
}

.submit-btn {
  background-color: #4CAF50;
}


/* Player Registration Button Container */
.player-registration-button-container {
  display: flex;               /* Use flexbox for alignment */
  flex-direction: column;      /* Arrange buttons vertically */
  gap: 15px;                   /* Space between buttons */
  align-items: center;         /* Center items horizontally */
  margin-top: 20px;           /* Space above the button container */
}

/* Add Player Button */
.player-registration-add-player-btn {
  background-color: #4CAF50;  /* Green background */
  color: white;                /* White text */
  border: none;                /* Remove border */
  padding: 10px 20px;         /* Add padding */
  font-size: 16px;             /* Increase font size */
  border-radius: 5px;         /* Rounded corners */
  cursor: pointer;             /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth background change */
}

/* Hover effect for Add Player Button */
.player-registration-add-player-btn:hover {
  background-color: #45a049;  /* Darker green on hover */
}

/* Save and Review Button */
.player-registration-save-and-review-btn {
  background-color: #2196F3;  /* Blue background */
  color: white;                /* White text */
  border: none;                /* Remove border */
  padding: 10px 20px;         /* Add padding */
  font-size: 16px;             /* Increase font size */
  border-radius: 5px;         /* Rounded corners */
  cursor: pointer;             /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth background change */
}

/* Hover effect for Save and Review Button */
.player-registration-save-and-review-btn:hover {
  background-color: #1e88e5;  /* Darker blue on hover */
}

.player-detail-photo-preview{
  height: 40px;
  width: 40px;

  /* position: absolute; */
 
}

.player-details-photo-preview-box{
  display: flex;
  flexDirection: row; 
  gap:8px;
}

.player-register-player-photo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px; /* Adjust margin for better spacing */
}
.player-card .player-register-player-photo-container{
  height: 180px;
  display: flex; /* Use Flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center;
}