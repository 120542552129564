/* CreateEvents.css */

/* General Styling */
.create-events-page {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.create-event-heading{
  text-align: center;
}

.add-event,
.event-list {
  width: 100%;
}

.add-event form {
  display: flex;
  flex-direction: column;
}

.add-event form div {
  margin-bottom: 10px;
}

.add-event form label {
  margin-bottom: 5px;
  font-weight: bold;
}

.add-event form input,
.add-event form textarea,
.add-event form select {
  padding: 8px;
  font-size: 1em;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
}

.add-event form button {
  padding: 10px;
  font-size: 1em;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  align-self: flex-start;
}

.add-event form button:hover {
  background-color: #45a049;
}

.event-list table {
  width: 100%;
  border-collapse: collapse;
}

.event-list table th,
.event-list table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.event-list table th {
  background-color: #f2f2f2;
  text-align: left;
}

.delete-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.delete-button:hover {
  background-color: #cc0000;
}

.confirmation-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 90%;
  max-width: 400px;
}

.popup-content p {
  margin-bottom: 20px;
  font-size: 16px;
}

.create-events-page .confirmation-popup button {
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 10px;
}

.create-events-page .confirmation-popup button.confirm {
  background-color: #007bff;
  color: white;
}

.create-events-page .confirmation-popup button.confirm:hover {
  background-color: #0056b3;
}

.create-events-page .confirmation-popup button.cancel {
  background-color: #ccc;
  color: black;
}

.create-events-page .confirmation-popup button.cancel:hover {
  background-color: #999;
}

/* Responsive Design */

/* For small screens like mobile phones */
@media (max-width: 600px) {
  .create-events-page {
      padding: 10px;
  }

  .add-event form div {
      margin-bottom: 15px;
  }

  .add-event form button {
      width: 100%;
      margin-top: 15px;
  }

  .event-list table th,
  .event-list table td {
      padding: 5px;
      font-size: 0.8em;
  }

  .popup-content p {
      font-size: 14px;
  }
}

/* For medium screens like tablets */
@media (min-width: 601px) and (max-width: 900px) {
  .create-events-page {
      padding: 15px;
  }

  .add-event form {
      flex-direction: column;
  }

  .add-event form button {
      width: 50%;
  }

  .event-list table th,
  .event-list table td {
      padding: 6px;
      font-size: 0.9em;
  }

  .popup-content p {
      font-size: 15px;
  }
}

/* For large screens like laptops and desktops */
@media (min-width: 901px) and (max-width: 1440px) {
  .create-events-page {
      padding: 20px;
  }

  .add-event form button {
      width: auto;
  }

  .event-list table th,
  .event-list table td {
      padding: 10px;
      font-size: 1em;
  }
}

/* For extra-large screens like 40-50 inch displays */
@media (min-width: 1441px) {
  .create-events-page {
      max-width: 1400px;
  }

  .add-event form button {
      padding: 12px;
      font-size: 1.1em;
  }

  .event-list table th,
  .event-list table td {
      padding: 12px;
      font-size: 1.1em;
  }

  .popup-content p {
      font-size: 18px;
  }
}

/* .createEvents{
    height: 50vh;
}

.dropdown-container {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
}

.dropdown-container label {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

.dropdown-container select {
    padding: 10px;
    font-size: 16px;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    color: #333;
    outline: none;
    transition: border-color 0.3s;
}


.dropdown-container select:focus {
    border-color: #007bff;
}


.dropdown-container option {
    padding: 10px;
    font-size: 16px;
} */
