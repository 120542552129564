.payment-page-body{
    width: 90%;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
}

.payment-page-details{
    margin-bottom: 20px;
    flex: 1;
    margin: 10px;
    padding: 20px;
    height: 70vh;
    
}

.payment-page-heading{
    /* margin-bottom: 40px; */
    font-size: large;
}


.payment-page-heading h2 {
    color: #333;
    margin-bottom: 10px;
  }

.payment-page-desc{
    justify-content: center;
}

.payment-page-content {
  margin-bottom: 20px;
}

.payment-page-QR-Code{
    height: 100%;
    margin-left: 20%;
    /* border: 2px solid #333; */
}

.payment-page-total-amount {
    margin-top: 10px;
    font-size: 18px;
    width: 40vh;
    background-color: #f9f9f9;
    border-radius: 6px;
    color: #333;
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding-left: 5px;
  }
  

  @media (max-width: 1100px) {
    .payment-page-details {
        flex-direction: column; /* Stack details vertically */
        align-items: center; /* Center align details */
        /* width: 80%;   */
    }

    .payment-page-QR-Code {
        margin-left: 0; /* Reset margin */
    }
    .payment-page-body{
        flex-direction: column;
    }
         
}

@media (max-width: 400px) {
    .payment-page-heading h2 {
        font-size: 20px; /* Reduce font size */
        /* width: 80%; */
    }

    .payment-page-total-amount {
        font-size: 16px; /* Reduce font size */
    }

    input[type="text"] {
        max-width: 100%; /* Full width for smaller screens */
    }
    .payment-page-body{
        flex-direction: column;
    }
}

@media (max-width: 380px) {
    .payment-page-QR-Code {
        width: 100%; /* Set to full width on very small screens */
    }
}