/* Mba.css */

.mba-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  margin-top: 0;
}

.mba-content {
  width: 60%;
  text-align: justify;
  font-size: 1.5em; /* Increase text size */
  line-height: 1.6; /* Increase line height for readability */
  color: #333;
  background-color: rgb(249 237 237 / 80%);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: add a shadow for better contrast */
}

/* Responsive styling */
@media (max-width: 1024px) {
  .mba-content {
    width: 80%;
    font-size: 1.3em;
  }
}

@media (max-width: 768px) {
  .mba-content {
    width: 90%;
    font-size: 1.2em;
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .mba-content {
    width: 100%;
    font-size: 1em;
    padding: 10px;
  }
}
