.user-dashboard {
    padding: 20px;
  }
  
  .team-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center; 
    align-items: center;     
    padding: 20px;   
  }
  
  h2 {
    margin-bottom: 20px;
  }
  