/* .adminpage{
    height: 50vh;
} */

.admin-container {
    width: 80%;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* height: 45vh; */
}

.admin-container h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.events-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.event-card {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    width: 300px;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: box-shadow 0.3s ease;
}

.event-card h3 {
    color: #007bff;
    margin-bottom: 10px;
}

.event-card p {
    color: #555;
    margin: 5px 0;
}

.event-card p:last-child {
    font-weight: bold;
    color: #333;
}
